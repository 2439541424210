import cookie from 'react-cookies';
/**
 *
 *
 * @class UtmService
 */
class UtmService {
  /**
   * Creates an instance of UtmService.
   * @memberof UtmService
   */
  constructor() {
    this.domain=`.shawacademy.com`;
  }


  /**
   *
   *
   * @param {*} name
   * @param {*} value
   * @param {*} expires
   * @param {*} cookieDomain
   * @memberof UtmService
   */
  setCookie(name, value, expires, cookieDomain) {
    const domain=cookieDomain||this.domain;
    const params={
      path: '/',
      domain,
      expires,
    };
    cookie.save(name, value, params);
  }

  /**
   *
   *
   * @param {*} e
   * @param {*} r
   * @memberof UtmService
   */
  oneDayCookie(e, r) {
    const o=new Date();
    let t=o.getTime();
    (t+=864e5), o.setTime(t);
    r && (this.setCookie(e, r, o),
    // check with Niranjan sir, if this is needed.
    (this.setCookie(e, r, o, '.liveonlineacademy.com')));
  }

  /**
   *
   *
   * @param {*} e
   * @param {*} r
   * @return {*}
   * @memberof UtmService
   */
  getParameterByName(e, r) {
    r || (r = window.location.href), (e = e.replace(/[\[\]]/g, '\\$&'));
    const o = new RegExp('[?&]' + e + '(=([^&#]*)|&|#|$)').exec(r);
    return o ? (o[2] ? decodeURIComponent(o[2].replace(/\+/g, ' ')) : '') :
    null;
  }

  /**
   *
   *
   * @param {*} e
   * @param {*} r
   * @return {*}
   * @memberof UtmService
   */
  getParameterByReferrer(e, r) {
    r || (r = document.referrer), (e = e.replace(/[\[\]]/g, '\\$&'));
    const o = new RegExp('[?&]' + e + '(=([^&#]*)|&|#|$)').exec(r);
    return o ? (o[2] ? decodeURIComponent(o[2].replace(/\+/g, ' ')) : '') :
    null;
  }

  /**
   *
   *
   * @param {*} e
   * @param {*} r
   * @return {*}
   * @memberof UtmService
   */
  getCode(e, r) {
    r || (r = window.location.href), (e = e.replace(/[\[\]]/g, '\\$&'));
    const o = new RegExp('[?&]' + e + '(=([^&#]*)|&|#|$)').exec(r);
    return o ? (o[2] ? decodeURIComponent(o[2]) : '') : null;
  }

  /**
   *
   *
   * @memberof UtmService
   */
  init() {
    let utmSource = this.getParameterByName('utm_source');
    let utmMedium = this.getParameterByName('utm_medium');
    let utmCampaign = this.getParameterByName('utm_campaign');
    const utmCreative = this.getParameterByName('utm_creative');
    const utmTerm = this.getParameterByName('utm_term');
    const utmContent = this.getParameterByName('utm_content');
    const offerCode = this.getParameterByName('offer_code');
    const voucherCodeFlag = this.getParameterByName('voucher_code');
    const rVoucherCodeFlag = this.getParameterByReferrer('voucher_code');
    const rOfferCode = this.getParameterByReferrer('offer_code');
    const rUtmSource = this.getParameterByReferrer('utm_source');
    const rUtmMedium = this.getParameterByReferrer('utm_medium');
    const rUtmCampaign = this.getParameterByReferrer('utm_campaign');
    const voucherCode = this.getCode('code');
    rUtmSource && (utmSource = rUtmSource),
    rUtmMedium && (utmMedium = rUtmMedium),
    rUtmCampaign && (utmCampaign = rUtmCampaign),
    rOfferCode && (offerCode = rOfferCode),
    rVoucherCodeFlag && (voucherCodeFlag = rVoucherCodeFlag),
    this.oneDayCookie('utm_source', utmSource),
    this.oneDayCookie('utm_medium', utmMedium),
    this.oneDayCookie('utm_campaign', utmCampaign),
    this.oneDayCookie('utm_creative', utmCreative),
    this.oneDayCookie('utm_term', utmTerm),
    this.oneDayCookie('utm_content', utmContent),
    this.oneDayCookie('code', voucherCode),
    this.oneDayCookie('offer_code', offerCode),
    this.oneDayCookie('voucher_code_flag', voucherCodeFlag);
  }
}

export default UtmService;
