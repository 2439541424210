import React from 'react';
import HomePageComponent from '../Templates/HomePageComponent';

const EnglishHomePage = () => {
  return (
    <HomePageComponent
      key={1}
    />
  );
};

EnglishHomePage.defaultProps = {
  data: {},
};

export default EnglishHomePage;
