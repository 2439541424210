import React, {useEffect, useState} from 'react';
// import Helmet from 'react-helmet';
// import styles from './Styles/homepage.module.scss';
// import styles from './Styles/staticpage.module.scss';
import Footer from './Shared/Footer';
// import {Link} from 'gatsby';
// import {Container} from 'react-bootstrap';
import Header from './Shared/Header';
// import htmr from 'htmr';
import '../styles/cookieconsent.min.css';
import * as utils from './Shared/Utils';
// import AssetsService from '../Application/Shared/AssetsService';
import UtmService from './Services/UtmService';
// import loadable from '@loadable/component';

// const Carousel=loadable(() => import('react-multi-carousel'));
/**
  * @param {object} coursePageData
  * @return {object} CoursePage
  */
function HomePageComponent() {
  // const locale=homePageData.locale;
  // const headerContent=homePageData.header;
  // const wideAssetsService = new AssetsService('WEBSITE_WIDE');
  // const squareAssetsService = new AssetsService('WEBSITE_SQUARE');
  const [loadComponent, setLoadComponent] = useState(false);

  // const loginUrl = ['en', 'en-us', 'en-gb'].includes(locale) ?
  // `/app/en/` : `/app/${locale}/`;
  // const localizedGeneralSignupLink =
  // utils.getLocalizedPageLink(locale, '/signup/');

  const handleScroll = () => {
    if (typeof window !== 'undefined' && window.scrollY > 100) {
      const images = document.getElementsByTagName('img');
      Array.from(images).forEach((img) => {
        if (img.dataset.src && img.src.indexOf(img.dataset.src) <= -1) {
          img.src = img.dataset.src;
        }
      });
      if (!loadComponent) {
        setLoadComponent(true);
      }
    }
  };
  useEffect(() => {
    const utmService=new UtmService();
    utmService.init();
    const trackingObj = {
      pageKind: 'WebsiteHomePage',
      locale: 'en',
      courseSlug: '',
      facultySlug: '',
    };
    utils.trackPageView(trackingObj);
    utils.initializePrices();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // const ButtonGroup = (button) => {
  //   const {carouselState: {currentSlide,
  //     totalItems, slidesToShow}} = button;
  //   const isEndOfList = currentSlide>=totalItems-slidesToShow;
  //   return (
  //     <div className={styles.carousalButtongroup}>
  //       <p className={`${styles.prev} ${!currentSlide && styles.disable}`}
  //         onClick={() => button.previous()}>{button.prevText}</p>
  //       <p className={`${styles.next} ${isEndOfList && styles.disable}`}
  //         onClick={() => button.next()}>{button.nextText}</p>
  //     </div>
  //   );
  // };

  // const canonicalList=homePageData.seo.canonicalurls;
  // const canonicalUrls=[];
  // if (canonicalList) {
  //   for (let index=0; index<canonicalList.length; index++) {
  //     canonicalUrls.push({
  //       'href': canonicalList[index].href,
  //       'rel': 'canonical',
  //     });
  //   }
  // }

  // const responsive = {
  //   desktop: {
  //     breakpoint: {max: 3000, min: 1024},
  //     slidesToSlide: 3,
  //     items: 3,
  //   },
  //   tablet: {
  //     breakpoint: {max: 1024, min: 768},
  //     slidesToSlide: 2,
  //     items: 2,
  //   },
  //   mobile: {
  //     breakpoint: {max: 768, min: 0},
  //     slidesToSlide: 1,
  //     items: 1,
  //   },
  // };

  // const metaVerify = '04e503c247d0a2c145b10baaa9a9f2f4';

  return (
    <div>
      {/* <SEO
        locale={homePageData.locale}
        title={homePageData.seo.title}
        description={homePageData.seo.description}
        canonicalUrls={canonicalUrls}
        languageCanonicals={languageCanonicals}
        schemaMarkup={schemaMarkup}
      /> */}
      {/* <Helmet>
        <script>
          window.dataLayer=window.dataLayer||[];
        </script>
      </Helmet> */}
      {/* <Helmet>
        <script async src="/js/cookieconsent-scripts.min.js"></script>
      </Helmet>
      <Helmet>
        <meta name='classpert-site-verification' content={metaVerify}/>
      </Helmet> */}
      <Header locale={'en'}
      />
      {/* <main className={styles.homePage}>
        <div className="feedback">
          <Link to ="https://form.typeform.com/to/QA6Nm2a4/?typeform-source=www.shawacademy.com"
            target="_blank">Feedback<span></span>
            <img src="/images/home/new/thumbs-up.png"
              data-src="/images/home/new/thumbs-up.png"
              width="24" height="24" alt="thumbs-up" />
          </Link>
        </div>
        {homePageData.sections.map((section, sectionIndex) => {
          let content= '<div></div>';
          if (section.pagesection) {
            content=section.pagesection.content;
          }


          if (section.herosection) {
            return (
              <Container key={`herosection-${sectionIndex}`}
                className={styles.heroBanner}>
                <div className={styles.bannerContent}>
                  <div className={styles.contentHead}>
                    <p>{section.herosection.heading}</p>
                  </div>
                  <div className={styles.contentInfo}>
                    <p>{section.herosection.subheading}</p>
                  </div>
                  <Link className={styles.startLearnCta}
                    to={localizedGeneralSignupLink}
                    rel="noindex, nofollow">
                    {section.herosection.signupcta}</Link>
                  <div className={styles.existingUserLogin}>
                    {section.herosection.existinguser}&nbsp;
                    <Link className={styles.loginCta}
                      to={loginUrl}>{section.herosection.logincta}</Link>
                  </div>
                  <div className={styles.checkInfo}>
                    <div className={styles.check}>
                      <img src="/images/home/new/checkmark.svg"
                        data-src="/images/home/new/checkmark.svg"
                        width="20" height="15" alt="checkmark" />
                      <p>{section.herosection.checkmarkpointone}</p>
                    </div>
                    <div className={styles.check}>
                      <img src="/images/home/new/checkmark.svg"
                        data-src="/images/home/new/checkmark.svg"
                        width="20" height="15" alt="checkmark" />
                      <p>{section.herosection.checkmarkpointtwo}</p>
                    </div>
                  </div>
                </div>
                <div className={styles.bannerImg} >
                  <img src="/assets/course-cards/homepage/home-banner-V4.png"
                    width="400" height="355" alt="hero-banner" /></div>
              </Container>
            );
          }


          if (section.courselistblock) {
            return (
              <React.Fragment key={`courselistblock-${sectionIndex}`}>
                <div className={styles.newHomeSkills}>
                  <section className={styles.popularCourseContainer}>
                    <div className={styles.popularHeader}>
                      <h3>
                        {section.courselistblock?.heading}
                      </h3>
                    </div>

                    <div className={styles.contentRow}>
                      {section.courselistblock.courselist
                          .map((course, index) => {
                            const courseId = course.courseslug;
                            const courseSignupURL = utils
                                .getLocalizedSignupLink(locale, courseId);
                            const courseURL = utils
                                .getLocalizedPageLink(locale, course.url);
                            const wideImage = wideAssetsService
                                .fetchAssetURL(courseId);
                            const squareImage = squareAssetsService
                                .fetchAssetURL(courseId);
                            return (
                              <div key={index}
                                className={styles.popularCard}>
                                <Link
                                  to={courseURL}>
                                  <div className={styles.cardBox}>
                                    <div className={styles.popluarCardImg}>
                                      <Link
                                        to={courseURL}>
                                        <img src="/images/transparent.svg"
                                          className={styles.desktopView}
                                          data-src={wideImage}
                                          alt={course.title}
                                          width="223px" height="134px"/>
                                        <span className={styles.aiIcon}>
                                          <img
                                            src="/images/transparent.svg"
                                            data-src={squareImage}
                                            alt={course.title}
                                            width="62px" height="62px"/>
                                        </span>
                                      </Link>
                                    </div>
                                    <div className={styles.popCardContent}>
                                      <div className={styles.cardInfo}>
                                        <div className={styles.popCourseName}>
                                          <Link
                                            to={courseURL}>
                                            {course.coursemenuname
                                                ?.replace(/(.{45})..+/, '$1…')}
                                          </Link>
                                        </div>
                                        <div className={styles.ratingContent}>
                                          <div
                                            className={styles.popEnrollCount}>
                                            {course.graduates?.toLocaleString()}
                                    &nbsp;
                                            {section.courselistblock?.
                                                graduatestext}
                                          </div>
                                          <div className={styles.starRating}>
                                            <span>{course.rating/10}</span>
                                            <img src="/images/transparent.svg"
                                              data-src=
                                                "/images/home/new/star.svg"
                                              alt="star" />
                                          </div>
                                        </div>
                                      </div>
                                      <div className={styles.popEnrollSection}>
                                        <Link
                                          to={courseSignupURL}
                                          className={styles.popEnrollCta}
                                          rel="noindex nofollow">
                                          {section.courselistblock.enrollnowcta}
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            );
                          } )}
                    </div>
                    {section.courselistblock.viewallcoursescta &&
                    <div className={styles.ctaSection}>
                      <Link to={cataloguePageData?.url}
                        className={styles.blueLargeCta}>
                        {section.courselistblock.viewallcoursescta}
                      </Link>
                    </div>
                    }
                  </section>
                </div>
              </React.Fragment>);
          }
          if (section.reviewslistblock) {
            return (
              <Container>
                <div key={`reviewslistblock-${sectionIndex}`}
                  className={styles.testimonySection}>
                  <div className={styles.testimonyTop}>
                    <div className={styles.testimonyHeadline}>
                      {section.reviewslistblock.heading}
                    </div>
                  </div>
                  {loadComponent &&<Carousel
                    responsive={responsive}
                    arrows={false}
                    customButtonGroup={<ButtonGroup
                      prevText={section.reviewslistblock.prevtext}
                      nextText={section.reviewslistblock.nexttext} />}
                    className={styles.testimonyBody}>
                    {section.reviewslistblock.reviews.map((review, index) => (
                      <div key={index} className={styles.testimony}>
                        <div className={styles.testimonyImg}>
                          <img src="/images/transparent.svg"
                            data-src={review.reviewerimage?.href}
                            alt={review.reviewerimage?.title} />
                          <div className={styles.rating}>
                            <p>{review.rating}</p>
                            <img src="/images/transparent.svg"
                              data-src='/images/home/new/star.svg' alt='star' />
                          </div>
                        </div>
                        <div className={styles.testimonyContent}>
                          {review.review}</div>
                      </div>
                    ))}
                  </Carousel>}
                </div>
              </Container>
            );
          }

          if (section.testimoniallistblock) {
            const testimonialList=section.testimoniallistblock.testimoniallist;
            return (
              <Container key={`testimoniallistblock-${sectionIndex}`}>
                <div className={styles.newHomeReview}>
                  {loadComponent && <Carousel
                    responsive={{
                      desktop: {
                        breakpoint: {max: 3000, min: 1024},
                        slidesToSlide: 1,
                        items: 1,
                      },
                      tablet: {
                        breakpoint: {max: 1024, min: 464},
                        slidesToSlide: 1,
                        items: 1,
                      },
                      mobile: {
                        breakpoint: {max: 464, min: 0},
                        slidesToSlide: 1,
                        items: 1,
                      },
                    }}
                    arrows={true}
                    customButtonGroup={<ButtonGroup
                      prevText={section.testimoniallistblock.prevtext}
                      nextText={section.testimoniallistblock.nexttext}
                    />} >
                    {testimonialList.map((testimonial, testimonialIndex) => (
                      <div key={testimonialIndex}
                        className={styles.newHomeReviewSection}>
                        <div className={styles.newHomeReviewSectionData}>
                          <p className={styles.reviewText}>
                            {testimonial.testimonialcontent}
                          </p>
                          <p className={styles.reviewName}>
                            <span>{testimonial.name},</span>
                            {testimonial.location}</p>
                          <p className={styles.reviewCourse}>
                            {testimonial.course}</p>
                        </div>
                        <div className={styles.newHomeReviewSectionImg}>
                          {testimonial.isimage?
                       <img src="/images/transparent.svg"
                         data-src={testimonial.testimonialasset.href}
                         alt={testimonial.testimonialasset.title}/>:
                          <iframe title="testimonial videos"
                            className={styles.reviewIframe}
                            src={testimonial.testimonialasset.href}
                            width='100%' height='100%' >
                          </iframe>}
                        </div>
                      </div>
                    ))}
                  </Carousel>}
                </div>

                <div className={styles.testimonialcta}>
                  <Link to={reviewPageData.url}
                    className={styles.pinkLargeCta}>
                    {section.testimoniallistblock.seemorecta}
                  </Link> </div>
              </Container>
            );
          }
          if (section.bloglistblock) {
            const blogResponsive = {
              desktop: {
                breakpoint: {max: 3000, min: 1025},
                slidesToSlide: 4,
                items: 4,
              },
              tablet: {
                breakpoint: {max: 1024, min: 768},
                slidesToSlide: 2,
                items: 2,
              },
              mobile: {
                breakpoint: {max: 768, min: 0},
                slidesToSlide: 1,
                items: 1,
              },
            };
            return (
              <div key={`bloglistblock-${sectionIndex}`} className=
                {styles.educatorBlogs}>
                <Container className={styles.educatorHeader}>
                  <Link
                    to='/blog/'
                    className={styles.educatorHeader}>
                    <span className={styles.educatorHeaders}>
                      {section.bloglistblock.heading}
                    </span>
                  </Link>
                </Container>
                <Container>
                  <Carousel
                    responsive={blogResponsive}
                    arrows={false}
                    className={styles.blogCards}
                    customButtonGroup={<ButtonGroup
                      prevText={section.bloglistblock?.prevtext}
                      nextText={section.bloglistblock?.nexttext}
                    />} >
                    {section.bloglistblock.featuredblogs.map((blog, index) => (
                      <a
                        key={index}
                        href={blog.url}
                        className={styles.blogCard}>
                        <div className={styles.blogCardImg}>
                          <img src="/images/transparent.svg"
                            data-src={blog.featuredimage.href}
                            alt={blog.featuredimage.title} />
                        </div>
                        <div className={styles.blogCardDetails}>
                          <div className={styles.blogCardText}>
                            {blog.title}
                          </div>
                          <div className={styles.blogCardEnd}>
                            <div className={styles.blogCardTopic}>
                              {blog.category}
                            </div>
                            <div className={styles.blogDate}>
                              {blog.publisheddate}
                            </div>
                          </div>
                        </div>
                      </a>
                    ))}
                  </Carousel>
                </Container>
              </div>
            );
          }


          return (<React.Fragment key={`section-${sectionIndex}`}>
            {htmr(content)}
          </React.Fragment>);
        })}
      </main> */}

      <Footer />
    </div>
  );
}

HomePageComponent.defaultProps = {
};

export default HomePageComponent;
